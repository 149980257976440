/*
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Loader from '../loader';
import StripeForm from '../stripe-form';
import { fetchStripeCustomerCards } from '../../lib/stripeUtils';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
class StripeCards extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            status: '',
            cards: {},
            message: '',
            loading: false,
            showForm: false,
        };
    }

    componentDidMount() {
        this.fetchCards();
    }

    fetchCards = () => {
        const {
            stripeCustomerId,
        } = this.props;

        if ( stripeCustomerId ) {
            this.setState( { loading: true } );

            fetchStripeCustomerCards( stripeCustomerId )
                .then( ( response ) => {
                    const {
                        data,
                    } = response;

                    const {
                        status,
                        message,
                        cards,
                        defaultCard,
                    } = data;

                    if ( status !== 'error' ) {
                        this.setState( {
                            status: 'success',
                            cards,
                            defaultCard,
                            loading: false,
                        } );
                    } else {
                        this.setState( {
                            status,
                            message,
                            loading: false,
                        } );
                    }
                } )
                .catch( ( error ) => {
                    this.setState( {
                        status: 'error',
                        message: error,
                        loading: false,
                    } );
                } );
        }
    };


    render() {
        const {
            status,
            defaultCard,
            cards,
            loading,
        } = this.state;

        if ( loading ) {
            return (
                <div className="cards-list-loader">
                    <Loader />
                </div>
            );
        }

        if ( status === 'error' ) {
            return <p>Sorry, there was an error loading your cards. Please <a href="mailto:hello@tomhirst.com">email
                me</a> to resolve.</p>;
        }

        if ( ! cards || cards.length < 1 ) {
            return (
                <div>
                    <p>You have no active cards.</p>
                </div>
            );
        }

        if ( cards && cards.length > 0 ) {
            return (
                <div>
                    <table className="cards-list">
                        <thead className="cards-list-head">
                            <tr>
                                <th>Card type</th>
                                <th>Card number</th>
                                <th>Expiry date</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody className="cards-list-item-body">
                            { cards.map( ( card ) => {
                                const {
                                    id,
                                    brand,
                                    exp_month,
                                    exp_year,
                                    last4,
                                } = card;
                                return (
                                    <tr key={ id } className="cards-list-item">
                                        <td>{ brand }</td>
                                        <td>XXXX XXXX XXXX { last4 }</td>
                                        <td>{ exp_month }/{ exp_year }</td>
                                        <td style={ { maxWidth: '200px' } }>
                                            { id === defaultCard && (
                                                <span>This is your default payment method</span>
                                            ) }
                                        </td>
                                    </tr>
                                );
                            } ) }
                        </tbody>
                    </table>
                    <StripeForm
                        onSuccess={ this.fetchCards }
                        addCardForm
                    />
                </div>
            );
        }

        return null;
    }
}

StripeCards.propTypes = {
    stripeCustomerId: PropTypes.string.isRequired,
};

export default StripeCards;
