/*
 * External dependencies
 */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import Loader from '../loader';
import { handleLogin } from '../../lib/auth';
import InternalLink from '../internal-link';

/**
 * Component
 */
class LoginForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            message: '',
            status: '',
            loading: false,
        };
    }

    handleFormFieldChange = ( event ) => {
        this.setState( { [ event.target.name ]: event.target.value } );
    };

    handleSubmit = ( event ) => {
        event.preventDefault();

        const {
            'email-address-mentorship-login': email,
            'password-mentorship-login': password,
        } = this.state;

        this.setState( {
            loading: true,
        } );

        handleLogin( email, password )
            .then( ( response ) => {
                if ( response ) {
                    if ( response.status !== 'error' ) {
                        this.setState( {
                            message: response.message ? response.message : '',
                            status: response.status ? response.status : '',
                            loading: false,
                        } );
                    } else {
                        this.setState( {
                            message: 'Incorrect email and password combination.',
                            status: 'error',
                            loading: false,
                        } );
                    }
                }
            } )
            .catch( () => {
                this.setState( {
                    message: 'Can\'t login at this time.',
                    status: 'error',
                    loading: false,
                } );
            } );
    };

    render() {
        const {
            message,
            status,
            loading,
        } = this.state;

        const formButtonAsideTextClasses = classNames( 'form-button-aside-text', status ? `is-${status}` : '' );

        return (
            <form className="form" id="login-form" onSubmit={ this.handleSubmit }>
                <fieldset>
                    <div className="input-wrap">
                        <label htmlFor="email-address-mentorship-login">
                            Email address
                            <input
                                name="email-address-mentorship-login"
                                id="email-address-mentorship-login"
                                type="email"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="password-mentorship-login">
                            Password
                            <input
                                name="password-mentorship-login"
                                id="password-mentorship-login"
                                type="password"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <div className="input-wrap d-md-flex align-items-md-center">
                        <button className="button is-green has-icon flex-md-shrink-0" type="submit">
                            Login
                            <FontAwesomeIcon icon="arrow-right" />
                        </button>
                        <span className={ formButtonAsideTextClasses }>
                            { ( loading && <Loader /> ) || message }
                        </span>
                    </div>
                    <div className="input-wrap">
                        <InternalLink to="/forgotten-password">Forgotten your password?</InternalLink>
                    </div>
                </fieldset>
            </form>
        );
    }
}

export default LoginForm;
