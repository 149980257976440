/*
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

/**
 * Internal dependencies
 */
import Button from '../button';
import Loader from '../loader';
import StripeForm from '../stripe-form';
import { fetchStripeCustomerSubscriptions, fetchStripeCustomerDiscount, modifySubscription } from '../../lib/stripeUtils';

/**
 * Stylesheets
 */
import './style.scss';

/**
 * Component
 */
class StripeSubscriptions extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            status: '',
            subscriptions: {},
            message: '',
            loading: false,
            showForm: false,
            discount: {
                loading: false,
                message: '',
                discount: {
                    coupon: {
                        name: '',
                        amount_off: 0,
                    },
                    end: 0,
                },
                status: '',
            },
        };
    }

    componentDidMount() {
        this.fetchSubscriptions();
        this.fetchDiscount();
    }

    fetchSubscriptions = () => {
        const {
            stripeCustomerId,
        } = this.props;

        if ( stripeCustomerId ) {
            this.setState( { loading: true } );

            fetchStripeCustomerSubscriptions( stripeCustomerId )
                .then( ( response ) => {
                    const {
                        data,
                    } = response;

                    const {
                        status,
                        message,
                        subscriptions,
                    } = data;

                    if ( status !== 'error' ) {
                        this.setState( {
                            status: 'success',
                            subscriptions,
                            loading: false,
                        } );
                    } else {
                        this.setState( {
                            status,
                            message,
                            loading: false,
                        } );
                    }
                } )
                .catch( ( error ) => {
                    this.setState( {
                        status: 'error',
                        message: error,
                        loading: false,
                    } );
                } );
        }
    };

    fetchDiscount = () => {
        const {
            stripeCustomerId,
        } = this.props;

        if ( stripeCustomerId ) {
            this.setState( { loading: true } );

            fetchStripeCustomerDiscount( stripeCustomerId )
                .then( ( response ) => {
                    const {
                        data,
                    } = response;

                    const {
                        status,
                        message,
                        discount,
                    } = data;

                    if ( status !== 'error' ) {
                        this.setState( {
                            discount: {
                                status: 'success',
                                discount: discount || this.state.discount.discount,
                                loading: false,
                            },
                        } );
                    } else {
                        this.setState( {
                            discount: {
                                ...this.state.discount,
                                status,
                                message,
                                loading: false,
                            },
                        } );
                    }
                } )
                .catch( ( error ) => {
                    this.setState( {
                        discount: {
                            ...this.state.discount,
                            status: 'error',
                            message: error,
                            loading: false,
                        },
                    } );
                } );
        }
    };

    handleCancelSubscription = subscriptionId => ( event ) => {
        event.preventDefault();

        const {
            email,
        } = this.props;

        this.setState( {
            loading: true,
        } );

        modifySubscription( subscriptionId, true, email )
            .then( ( response ) => {
                if ( response && response.data && response.data.status === 'success' ) {
                    this.fetchSubscriptions();
                }
            } )
            .catch ( ( error ) => {
                this.setState( {
                    loading: false,
                } );
            } );
    };

    handleReactivateSubscription = subscriptionId => ( event ) => {
        event.preventDefault();

        const {
            email,
        } = this.props;

        this.setState( {
            loading: true,
        } );

        modifySubscription( subscriptionId, false, email )
            .then( ( response ) => {
                if ( response && response.data && response.data.status === 'success' ) {
                    this.fetchSubscriptions();
                }
            } )
            .catch ( ( error ) => {
                console.log( error );
                this.setState( {
                    loading: false,
                } );
            } );
    };

    handleRestartSubscription = ( event ) => {
        event.preventDefault();
        this.setState( {
            showForm: true,
        } );
    };

    renderSubscriptionButton = ( subscription ) => {
        if ( ! subscription ) {
            return null;
        }

        const {
            cancel_at_period_end: cancelAtPeriodEnd,
            id,
            status,
        } = subscription;

        if ( status === 'active' && ! cancelAtPeriodEnd ) {
            return (
                <Button
                    onClick={ this.handleCancelSubscription( id ) }
                    text="Cancel"
                    color="grey"
                    small
                    anchor
                />
            );
        }

        if ( status === 'active' && cancelAtPeriodEnd ) {
            return (
                <Button
                    onClick={ this.handleReactivateSubscription( id ) }
                    text="Reactivate"
                    color="green"
                    small
                    anchor
                />
            );
        }

        return null;
    };

    render() {
        console.log( this.state );

        const {
            status,
            subscriptions,
            loading,
            showForm,
            discount: {
                discount: {
                    coupon: {
                        name: discountName,
                        amount_off: amountOff,
                    },
                    end: discountEndDate,
                },
            },
        } = this.state;



        if ( loading || this.state.discount.loading ) {
            return (
                <div className="subscriptions-list-loader">
                    <Loader />
                </div>
            );
        }

        const amountOffTrimmed = amountOff ? parseInt( amountOff.toString().slice( 0, -2 ) ) : 0;
        const discountActive = moment( new Date() ).isBefore( moment( discountEndDate * 1000 ) );

        if ( status === 'error' ) {
            return <p>Sorry, there was an error loading your subscriptions. Please <a href="mailto:hello@tomhirst.com">email me</a> to resolve.</p>;
        }

        if ( ! subscriptions || subscriptions.length < 1 ) {
            return (
                <div>
                    <p>You have no active subscriptions.</p>
                    { ! showForm ? (
                        <Button
                            onClick={ this.handleRestartSubscription }
                            icon="arrow-right"
                            text="Restart Your Mentorship"
                            color="green"
                            anchor
                        />
                    ) : (
                        <div>
                            <h3>Restart Mentorship</h3>
                            <p>Fill out the form below to restart your mentorship subscription.</p>
                            <p>You&apos;ll be billed &pound;99 per month, starting today. You can cancel again at any time.</p>
                            <StripeForm
                                onSuccess={ this.fetchSubscriptions }
                                restartForm
                            />
                        </div>
                    ) }
                </div>
            );
        }

        if ( subscriptions && subscriptions.length > 0 ) {
            return (
                <>
                    { discountActive && (
                        <div className="discount-message">
                            <p>{ discountName } ends { moment( discountEndDate * 1000 ).format( 'DD/MM/YYYY' ) }.</p>
                        </div>
                    ) }
                    <table className="subscriptions-list">
                    <thead className="subscriptions-list-head">
                        <tr>
                            <th>Subscription</th>
                            <th>Price</th>
                            <th>Created on</th>
                            <th>Current active period</th>
                            <th>Status</th>
                            <th>&nbsp;</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody className="subscriptions-list-item-body">
                        { subscriptions.map( ( subscription ) => {
                            const {
                                id,
                                status,
                                cancel_at: cancelAt,
                                canceled_at: canceledAt,
                                cancel_at_period_end: cancelAtPeriodEnd,
                                current_period_start: currentPeriodStart,
                                current_period_end: currentPeriodEnd,
                                plan,
                            } = subscription;

                            const {
                                nickname,
                                amount,
                            } = plan;

                            const amountTrimmed = parseInt( amount.toString().slice( 0, -2 ) );
                            const price = discountActive ? amountTrimmed - amountOffTrimmed : amountTrimmed;

                            return (
                                <tr key={ id } className="subscriptions-list-item">
                                    <td>{ nickname }</td>
                                    <td><strong>Price:</strong> { new Intl.NumberFormat( 'en-GB', { style: 'currency', currency: 'GBP' } ).format( price ) }</td>
                                    <td><strong>Current period:</strong> { moment( currentPeriodStart * 1000 ).format( 'DD/MM/YYYY' ) } - { moment( currentPeriodEnd * 1000 ).format( 'DD/MM/YYYY' ) }</td>
                                    <td><strong>Status:</strong> { status }</td>
                                    <td>
                                        { canceledAt ? (
                                            <span>
                                                Cancelled on { moment( canceledAt * 1000 ).format( 'DD/MM/YYYY' ) }.<br />
                                                { cancelAtPeriodEnd && <span>Your subscription will end on { moment( cancelAt * 1000 ).format( 'DD/MM/YYYY' ) }</span> }.<br />
                                                No new charges will be raised.
                                            </span>
                                        ) : (
                                            <div>
                                                { ( status === 'incomplete' ) ? <span>Payment for your subscription could not be taken. Please check your emails for details.</span> : <span>Your subscription will automatically renew at the end of the current period.</span> }
                                            </div>
                                        ) }
                                    </td>
                                    <td>
                                        { this.renderSubscriptionButton( subscription ) }
                                    </td>
                                </tr>
                            );
                        } ) }
                    </tbody>
                </table>
                </>
            );
        }

        return null;
    }
}

StripeSubscriptions.propTypes = {
    email: PropTypes.string.isRequired,
    stripeCustomerId: PropTypes.string.isRequired,
};

export default StripeSubscriptions;
