/**
 * External dependencies
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Button from '../components/button';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import LoginForm from '../components/login-form';
import StripeCards from '../components/stripe-cards';
import StripeSubscriptions from '../components/stripe-subscriptions';
import { isLoggedIn, logout, getCurrentUser } from '../lib/auth';
import ChangePasswordForm from '../components/change-password-form';

class AccountPage extends Component {
    handleLogout = () => {
        logout( () => {
            window.location.replace( '/login' );
        } );
    };

    render() {
        const {
            location,
        } = this.props;

        const user = getCurrentUser();

        const {
            emailAddress,
            name,
            stripeCustomerId,
        } = user;

        return (
            <Layout
                location={location}
                noSignUpForm
            >
                <SEO
                    title="Account"
                />
                { isLoggedIn() ? (
                    <Section narrow>
                        <Button
                            onClick={ this.handleLogout }
                            small
                            text="Logout"
                            color="grey"
                            anchor
                        />
                        <h1>Account</h1>
                        <p className="lead">
                            { name ? `Hi, ${name}!` : 'Hi!' }
                        </p>
                        <h3>Your subscription</h3>
                        <StripeSubscriptions stripeCustomerId={ stripeCustomerId } email={ emailAddress } />
                        <h3>Payment method</h3>
                        <StripeCards stripeCustomerId={ stripeCustomerId } />
                        <h3>Change password</h3>
                        <ChangePasswordForm />
                    </Section>
                ) : (
                    <Section narrow>
                        <h1>Account</h1>
                        <p className="lead">
                            You need to be logged in:
                        </p>
                        <LoginForm />
                    </Section>
                ) }
            </Layout>
        );
    }
}

AccountPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

AccountPage.defaultProps = {
    location: {},
};

export default AccountPage;
