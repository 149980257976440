/*
 * External dependencies
 */
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

/**
 * Internal dependencies
 */
import Loader from '../loader';
import { handleChangePassword, getCurrentUser } from '../../lib/auth';

/**
 * Component
 */
class ChangePasswordForm extends Component {
    constructor( props ) {
        super( props );

        this.state = {
            message: '',
            status: '',
            loading: false,
        };
    }

    handleFormFieldChange = ( event ) => {
        this.setState( { [ event.target.name ]: event.target.value } );
    };

    handleSubmit = ( event ) => {
        event.preventDefault();

        const {
            'current-password-mentorship-reset-password': currentPassword,
            'new-password-mentorship-reset-password': newPassword,
            'confirm-new-password-mentorship-reset-password': confirmNewPassword,
        } = this.state;

        const user = getCurrentUser();

        const {
            emailAddress,
        } = user;

        this.setState( {
            loading: true,
        } );

        if ( newPassword !== confirmNewPassword ) {
            this.setState( {
                message: 'Your new passwords do not match.',
                status: 'error',
                loading: false,
            } );

            return;
        }

        handleChangePassword( emailAddress, currentPassword, newPassword )
            .then( ( response ) => {
                if ( response ) {
                    const {
                        message,
                        status,
                    } = response;

                    this.setState( {
                        message: message || '',
                        status: status || '',
                        loading: false,
                    } );
                }
            } )
            .catch( () => {
                this.setState( {
                    message: 'Can\'t change your password at this time.',
                    status: 'error',
                    loading: false,
                } );
            } );
    };

    render() {
        const {
            message,
            status,
            loading,
        } = this.state;

        const formButtonAsideTextClasses = classNames( 'form-button-aside-text', status ? `is-${status}` : '' );

        return (
            <form className="form" id="mentorship-reset-password-form" onSubmit={ this.handleSubmit }>
                <fieldset>
                    <div className="input-wrap">
                        <label htmlFor="password-login">
                            Current password
                            <input
                                name="current-password-mentorship-reset-password"
                                id="current-password-mentorship-reset-password"
                                placeholder="Enter your existing password"
                                type="password"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="password-login">
                            New password
                            <input
                                name="new-password-mentorship-reset-password"
                                id="new-password-mentorship-reset-password"
                                placeholder="Choose a new password"
                                type="password"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <div className="input-wrap">
                        <label htmlFor="password-login">
                            Confirm new password
                            <input
                                name="confirm-new-password-mentorship-reset-password"
                                id="confirm-new-password-mentorship-reset-password"
                                placeholder="Enter your new password again"
                                type="password"
                                onChange={ this.handleFormFieldChange }
                                required
                            />
                        </label>
                    </div>
                    <div className="input-wrap d-md-flex align-items-md-center">
                        <button className="button is-green has-icon flex-md-shrink-0" type="submit">
                            Change password
                        </button>
                        <span className={ formButtonAsideTextClasses }>
                            { ( loading && <Loader /> ) || message }
                        </span>
                    </div>
                </fieldset>
            </form>
        );
    }
}

export default ChangePasswordForm;
